import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { httpRequest } from "./util/common";

import "./styles/font.scss";
import "./styles/app.scss";
import "./styles/form.scss";

/* 레이아웃  컴포넌트 */
import MainLayout from "./layout/MainLayout/MainLayout";
import SubMenuLayout from "@/layout/SubMenuLayout/SubMenuLayout";

/* 커스텀 리액트 라우터*/
import AppRoute from "./util/AppRoute";
import PublicRoute from "./util/PublicRoute";
import PrivateRoute from "./util/PrivateRoute";

/* 웹소켓 오브젝트 생성*/
import { createSocket } from "./commonSlices/socketSlice";

/* route 폴더 */
import Home from "./routes/Home/Home";
import Service from '@/routes/Service/Service';
import PricePolicy from "./routes/PricePolicy/PricePolicy";
import RegistConfirm from "./routes/RegistConfirm/RegistConfirm";
import Error from "./routes/Error/Error";
import OrderSuccess from "@/routes/paymentResult/OrderSuccess";
import OrderFail from "./routes/paymentResult/OrderFail";
import OrderCancel from "./routes/paymentResult/OrderCancel";
import QuitSuccess from "./routes/QuitSuccess/QuitSuccess";
import RecoverSuccess from "./routes/RecoverSuccess/RecoverSuccess";

/* serviceModule 폴더 */
import ChangePasswordContainer from "./serviceModule/ChangePassword/ChangePasswordContainer";
import BoardNewsContainer from "./serviceModule/BoardNews/BoardNewsContainer";
import LoginContainer from "./serviceModule/Login/LoginContainer";
import JoinContainer from "./serviceModule/Join/JoinContainer";
import ChangeContainer from "./serviceModule/Change/ChangeContainer";
import UserInfoContainer from "./serviceModule/UserInfo/UserInfoContainer";
import SendAuthMailContainer from "./serviceModule/SendAuthMail/SendAuthMailContainer";
import SendContainer from "./serviceModule/Send/SendContainer";
import PointHistoryContainer from "./serviceModule/PointHistory/PointHistoryContainer";
import PatentPublicationContainer from "./serviceModule/PatentPublication/PatentPublicationContainer";
import TransHistoryContainer from "./serviceModule/TransHistory/TransHistoryContainer";
import QuitContainer from "./serviceModule/Quit/QuitContainer";
import WishListContainer from "./serviceModule/WishList/WishListContainer";
import MyDocumentContainer from "./serviceModule/MyDocument/MyDocumentContainer";
import RegistResultContainer from "./serviceModule/RegistResult/RegistResultContainer";
import BoardFaqContainer from "./serviceModule/BoardFaq/BoardFaqContainer";
import BoardNewsDtlContainer from "./serviceModule/BoardNewsDtl/BoardNewsDtlContainer";
import OrderPaymentContainer from "./serviceModule/OrderPayment/OrderPaymentContainer";
import LandingLayout from '@/layout/LandingLayout/LandingLayout';

class App extends Component {
  /**
   * 웹소켓 연결
   */
  componentDidMount() {
    const { user } = this.props.auth;
    const { createSocket } = this.props;
    if (user) {
      httpRequest.get("access/token").then((res) => {
        createSocket({ userIdx: user.idx, token: res.data });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.user !== prevProps.auth.user && this.props.auth.user) {
      const { user } = this.props.auth;
      const { createSocket } = this.props;
      httpRequest.get("access/token").then((res) => {
        createSocket({ userIdx: user.idx, token: res.data });
      });
    }
  }

  /**
   * 라우팅
   * @returns
   */
  render() {
    return (
      <Switch>
        {/* 로그인 전 접근가능화면 */}
        <AppRoute exact path="/" layout={MainLayout} component={Home} />
        <AppRoute
          path="/info/service"
          layout={LandingLayout}
          component={Service}
        />
        <AppRoute
          path="/info/pricePolicy"
          layout={MainLayout}
          component={PricePolicy}
        />
        <AppRoute
          exact
          path="/board/news"
          layout={SubMenuLayout}
          component={BoardNewsContainer}
        />
        <AppRoute
          path="/board/news/:id"
          layout={SubMenuLayout}
          component={BoardNewsDtlContainer}
        />
        <AppRoute
          path="/board/faq"
          layout={SubMenuLayout}
          component={BoardFaqContainer}
        />
        <PublicRoute
          path="/login"
          layout={MainLayout}
          component={LoginContainer}
        />
        <PublicRoute
          path="/join/form"
          layout={MainLayout}
          component={JoinContainer}
        />
        {/* 가입이메일 인증안내 */}
        <PublicRoute
          exact
          path="/join/regist"
          layout={MainLayout}
          component={RegistConfirm}
        />
        {/* 사용자 이메일 링크 클릭 시 진입 > 가입인증 성공/실패 페이지 */}
        <PublicRoute
          path="/join/regist/auth"
          layout={MainLayout}
          component={RegistResultContainer}
        />
        {/* 인증메일 재발송 */}
        <PublicRoute
          path="/sendAuthMail"
          layout={MainLayout}
          component={SendAuthMailContainer}
        />
        {/* 비밀번호 찾기 */}
        <PublicRoute
          path="/recover/send/form"
          layout={MainLayout}
          component={SendContainer}
        />
        {/* 사용자 이메일 링크 클릭 시 진입 > 비밀번호 찾기 > 비밀번호 변경 */}
        <PublicRoute
          path="/recover/regist/auth"
          layout={MainLayout}
          component={ChangeContainer}
        />
        <PublicRoute
          path="/recover/success"
          layout={MainLayout}
          component={RecoverSuccess}
        />
        {/* 에러발생 시 화면 */}
        <AppRoute path="/error" layout={MainLayout} component={Error} />

        {/* 로그인 후 접근가능화면 */}
        <PrivateRoute
          path="/my/pointHistory"
          layout={MainLayout}
          component={PointHistoryContainer}
        />
        <PrivateRoute
          path="/search/patentPublication"
          layout={MainLayout}
          component={PatentPublicationContainer}
        />
        <PrivateRoute
          path="/translation/myDoc/uploader"
          layout={MainLayout}
          component={MyDocumentContainer}
        />
        <PrivateRoute
          path="/my/transHistory"
          layout={MainLayout}
          component={TransHistoryContainer}
        />
        <PrivateRoute
          path="/payment/orderPayment"
          layout={MainLayout}
          component={OrderPaymentContainer}
        />
        <PrivateRoute
          path="/payment/orderSuccess"
          layout={MainLayout}
          component={OrderSuccess}
        />
        <PrivateRoute
          path="/payment/orderFail"
          layout={MainLayout}
          component={OrderFail}
        />
        <PrivateRoute
          path="/payment/orderCancel"
          layout={MainLayout}
          component={OrderCancel}
        />
        {/* 내정보 > 회원정보/비밀번호변경/회원탈퇴 */}
        <PrivateRoute
          path="/my/userInfo"
          layout={SubMenuLayout}
          component={UserInfoContainer}
        />
        <PrivateRoute
          path="/my/changePassword"
          layout={SubMenuLayout}
          component={ChangePasswordContainer}
        />
        <PrivateRoute
          path="/my/quit"
          layout={SubMenuLayout}
          component={QuitContainer}
        />
        <PublicRoute
          path="/quit/success"
          layout={MainLayout}
          component={QuitSuccess}
        />
        <PrivateRoute
          path="/my/wishList"
          layout={MainLayout}
          component={WishListContainer}
        />

        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps, { createSocket })(App);
